import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config.json';
import './App.css';
import Modal from 'react-bootstrap/Modal';

const Picture = () =>
{
    const { pictureId } = useParams();
    const [pictureData, setPictureData] = useState()
    const [emailInput, setEmailInput] = useState()
    const [nameInput, setNameInput] = useState()
    const [telInput, setTelInput] = useState()
    const [invalidEmail, setInvalidEmail] = useState()
    const [invalidName, setInvalidName] = useState()
    const [invalidTel, setInvalidTel] = useState()
    const [validatedName, setValidatedName] = useState()
    const [validatedEmail, setValidatedEmail] = useState()
    const [validatedTel, setValidatedTel] = useState()
    const [optout1, setOptout1] = useState()
    const [optout2, setOptout2] = useState()

    useEffect(() =>
    {
        fetch(config.pictureServerUrl + '/picture/' + pictureId, {
            method: 'GET',
            headers: {
                'Authorization': 'Token ' + config.token,
            },
        })
            .then((response) =>
            {
                if (response.status === 200)
                {
                    return response.blob()
                }
                /** Simply ignore any errors */
                return undefined
            })
            .then((data) =>
            {
                if (data)
                {
                    setPictureData(URL.createObjectURL(data))
                }
                else
                {
                    setPictureData(undefined)
                }
            })
            .catch((error) =>
            {
                console.log(error);
            })

    }, [pictureId])

    function log(msg)
    {
        let logUrl = config.pictureServerUrl + "/log";
        fetch(logUrl, {
            method: 'POST',
            body: "{ \"msg\": \"" + msg +"\" }",
            headers: {
                'Authorization': 'Token ' + config.token,
                'Content-Type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch((error) =>
            {
                console.log(error);
            })
    }

    function doOptOut1()
    {
        setOptout1(true)
    }

    function doConfirm1()
    {
        const validEmail = String(emailInput).toLowerCase().match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
        setInvalidEmail(!validEmail);
        if (validEmail)              
        {
            setValidatedEmail(emailInput)
            log("Email: " + emailInput);
        }
    }

    function doOptOut2()
    {
        setOptout2(true)
    }

    function doConfirm2()
    {
        const validEmail = String(emailInput).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        setInvalidEmail(!validEmail);
        
        const validName = nameInput && String(nameInput).length >= 2;
        setInvalidName(!validName);

        const validTel = String(telInput).toLowerCase().match(
            /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im
          );
        setInvalidTel(!validTel);

        if(validEmail && validName && validTel)
        {
            setValidatedEmail(emailInput)
            setValidatedTel(telInput)
            setValidatedName(nameInput)
            log("Email: " + emailInput + ", Tel: " + telInput + ", Name: " + nameInput);
        }
    }

    return (
        <div className="fullBody">
            {!pictureData && <div>No image exists with ID: {pictureId}</div>}
            { !(validatedEmail || optout1) && pictureData &&
            <div>
            <img alt="header" className="imgHead" src="/header.jpg"></img>
            <Modal.Dialog>
                <Modal.Header>
                <Modal.Title className="headerText">Wil je kans maken op hardloopschoenen?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <p>Vul dan je e-mail adres in</p>
                <label>E-mail:</label>
                <input name="email1" onInput={e => setEmailInput(e.target.value)} />
                { invalidEmail && <p className='error formIndent'>Ongeldig e-mail adres</p>}
                </Modal.Body>

                <Modal.Footer>
                <Button variant="secondary" onClick={doOptOut1}>Nee dank je</Button>
                <Button className="enexisButton" variant="primary" onClick={doConfirm1}>Ja zeker!</Button>
                </Modal.Footer>
            </Modal.Dialog>
            </div>
            }
            { (validatedEmail || optout1) && !((validatedEmail && validatedTel && validatedName) || optout2) &&
            <div>
            <img alt="header" className="imgHead" src="/header.jpg"></img>
            <Modal.Dialog>
                <Modal.Header>
                <Modal.Title className="headerText"> Heb je interesse in een baan bij Enexis?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <p>Vul hier nog je naam, e-mail adres en telefoonnummer in. Dan neemt een van onze recruiters contact met je op.</p>
                <label>Naam:</label>
                <input name="name" onInput={e => setNameInput(e.target.value)} />
                { invalidName && <p className='error formIndent'>Voer een naam in</p>}

                <label>E-mail:</label>
                <input name="email2" value={emailInput} onInput={e => setEmailInput(e.target.value)} />
                { invalidEmail && <p className='error formIndent'>Ongeldig e-mail adres</p>}
                
                <label>Telefoon:</label>
                <input name="tel" onInput={e => setTelInput(e.target.value)} />
                { invalidTel && <p className='error formIndent'>Ongeldig telefoonnummer</p>}

                </Modal.Body>

                <Modal.Footer>
                <Button variant="secondary" onClick={doOptOut2}>Nee dank je</Button>
                <Button className="enexisButton" variant="primary" onClick={doConfirm2}>Ja zeker!</Button>
                </Modal.Footer>
            </Modal.Dialog>
            </div>
            }
            {
                (validatedEmail || optout1) && ((validatedEmail && validatedTel && validatedName) || optout2) && pictureData &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxHeight: "100vh",
                }}>
                <div style={{
                    flex: "1",
                    maxHeight: "90vh",
                }}>
                    <img
                        src={pictureData}
                        alt='none'
                        style={{
                            maxHeight: "90vh",
                            maxWidth: "100%",
                        }} />
                </div>
                <div style={{
                    flex: "1",
                }}>
                    <a
                        href={pictureData}
                        rel="noopener noreferrer"
                        download={pictureId}>
                        <Button className="enexisButton" variant="primary" style={{height:"10vh"}}>
                            Save Image
                        </Button>
                    </a>
                </div>
                </div>
            }
        </div>
    );
};
export { Picture };